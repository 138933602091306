import React from "react";
import Section from "../../components/Section/Section";
import "./Quote.scss";

const Quote = ({ authorAndTitleCompany, quoteText }) => {
  return (
    <Section className="Quote">
      <div className="container">
        <figure>
          <blockquote>
            <h4 dangerouslySetInnerHTML={{ __html: `“${quoteText}”` }} />
          </blockquote>
          <div
            className="QuoteText Body--small"
            dangerouslySetInnerHTML={{ __html: authorAndTitleCompany }}
          />
        </figure>
      </div>
    </Section>
  );
};

export default Quote;
